.App {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to bottom, #000000, #121212);
  padding-bottom: 50px;
}

h1 {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

h2 {
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  opacity: 0.9;
}

h3 {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
}

h4.heading {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 8px;
  font-size: 22px;
  letter-spacing: 0.5px;
}

h5 {
  font-size: 20px;
  padding-right: 5px;
}

.avatar {
  border-radius: 50%;
  height: 85px;
  border: 3px solid #1f1f1f;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.avatar:hover {
  transform: scale(1.05);
}

.bio {
  display: flex;
  max-width: 500px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  border-radius: 23px;
  padding: 25px 0;
  animation: fadeIn 1s ease-out;
}

.about, .projects {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin-bottom: 40px;
}

.links {
  width: 100%;
  display: flex;
  justify-content: center;
}

.links > div {
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.heading {
  margin: 0 auto;
}

.job-container {
  display: flex;
  margin: 30px 0px 30px 0px;
  position: relative;
  padding-bottom: 30px;
}

.job-container:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(120, 120, 120, 0.2),
    transparent
  );
}

.link-container {
  display: flex;
  margin: 10px 0px;
}

.years, .name {
  min-width: 150px;
  margin: 5px 0;
  color: #999999;
  font-weight: 500;
}

.information {
  flex-grow: 2;
  transition: all 0.3s ease;
}

.job-container:hover .information {
  transform: translateX(5px);
}

.title-company, .social-link {
  margin: 0;
  text-decoration: none;
  color: #f5f5f5;
  font-weight: 500;
  transition: color 0.2s ease;
}

a.title-company:hover, .social-link:hover {
  text-decoration: underline;
  color: #e0e0e0;
}

.description {
  margin: 10px 0 0 0;
  line-height: 1.6;
  opacity: 0.9;
}

.technologies {
  margin: 15px 0 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.tech {
  margin: 0;
  background-color: #27272a;
  border-radius: 16px;
  padding: 5px 12px;
  display: inline-block;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  border: 1px solid #333333;
}

.tech:hover {
  background-color: #323238;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.techno-individual {
  width: 100%;
  size: cover;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.portfolio {
  width: 100%;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 40px;
}

.portfolio-cards {
  justify-content: space-around;
  width: 100%;
}

.portfolio-card {
  color: white;
  text-align: center;
  flex-shrink: 1;
  flex-basis: 200px;
  z-index: 2;
  transition: transform 1.2s ease-out;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.portfolio-card:hover {
  transform: translateY(-5px);
}

.portfolio-card > a > img {
  max-width: 100%;
  transition: transform 1.5s ease-out;
}

.portfolio-card:hover > a > img {
  transform: scale(1.02);
}

a.project-title {
  text-decoration: none;
  color: #f5f5f5;
  transition: color 0.2s ease;
}

a.project-title:hover {
  text-decoration: underline;
  color: #e0e0e0;
}

.project-title > h5 {
  font-size: 15px;
}

.project-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-description {
  max-width: 400px;
  text-align: center;
  position: relative;
  transition: top 0.5s ease, opacity 0.5s ease, position 0.5s ease;
}

.portfolio-technologies {
  justify-content: space-evenly;
  display: flex;
  position: relative;
  transition: top 0.5s ease, opacity 0.5s ease, position 0.5s ease;
}

.portfolio-technologies > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.portfolio-tech {
  font-weight: bold;
  font-size: 15px;
}

.bouncing-icon {
  animation: bounce-left-right 2s infinite; /* Adjust timing as needed */
}

@media (min-width: 1500px) {
  .portfolio {
    max-width: 1500px;
  }
}

@media (min-width: 1024px) {
  .project-description.lg-visible {
    display: block;
    top: 0;
    opacity: 1;
    z-index: 10;
  }

  .portfolio-technologies.lg-visible {
    display: flex;
    top: 0;
    opacity: 1;
    z-index: 10;
  }

  .portfolio:hover {
    transition: min-height 0.5s ease;
  }
}

@media (max-width: 1024px) {
  /* Styles for tablets */
  .portfolio {
    max-width: 100%;
  }

  .portfolio-card {
    max-height: 100vh;
  }

  .project-description, .portfolio-technologies {
    position: relative;
    top: 0;
    opacity: 1;
  }

  .portfolio-technologies {
    margin-bottom: 0;
  }
}

@media (max-width: 640px) {
  /* Styles for phones */
  .portfolio-technologies {
    width: 100%;
  }

  .App {
    width: 100%;
    padding: 20px;
  }

  .about, .work-experience, .projects, .links {
    max-width: 90%;
  }

  .job-container {
    display: flex;
    margin: 30px 0px 30px 0px;
    flex-direction: column;
  }

  .job-container:hover .information {
    transform: translateX(0);
  }

  .years {
    margin-bottom: 10px;
    font-size: 0.9rem;
  }

  .link-container {
    flex-direction: column;
    justify-content: center;
  }

  .heading {
    margin: 0 auto;
  }
}

@keyframes bounce-left-right {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-body {
  line-height: 1.7;
}

.about, .portfolio, .work-experience {
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

.work-experience {
  animation-delay: 0.2s;
}

.portfolio {
  animation-delay: 0.6s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
